<template>
  <div>
    <div class="my-5 hotelplusflight booking">
      <div class="container">
        <router-view />
      </div>
    </div>

    <b-modal
      id="errorAlertModal"
      centered
      no-close-on-backdrop
      size="lg"
      :hide-footer="true"
      dialog-class="understoodmodal modal-dialog-centered"
    >
      <template #modal-header="{  }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => hideError()"></button>
      </template>

      <!-- <p>על מנת שנוכל למצוא לך את הטיסה הטובה והמתאימה ביותר, <strong>יש לבחור את יעד הטיסה</strong></p> -->
      <p>{{ errorMsg }}</p>
      <div class="d-inline-flex mt-2">
        <a href="#" class="btn btn-outline-primary" @click="() => hideError()">{{$t("sabre.buttons.understood-thank")}}</a>
      </div>
    </b-modal>
    <!-- <a href="javascript:void(0)" id="toTopBtn"
     class="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out" :class="{'d-none': !isScroll}"
     data-abc="true"
     @click="() => gotoTop()"></a> -->

     <a id="button" :class="{'show': isScroll}" @click="() => gotoTop()"><span><i class="fa fa-angle-up" aria-hidden="true"></i></span></a>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/datepicker';

export default {
  metaInfo: {
    script: [
      { src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js', async: true, defer: true },
    ],
  },
  components: {
    BModal,
  },
  data() {
    return {
      isMountedEnd: false,
      isScroll: false,
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      errorMsg: 'GET_SABRE_ERROR_ALERT_MESSAGE',
    }),
  },
  watch: {
    errorMsg() {
      if (this.errorMsg) {
        this.showError();
      }
    },
  },
  created() {
    // this.$store.commit('SET_CURRENT_PAGE', 'sabre');
  },
  mounted() {
    // if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
    this.$root.$on('goto-top-to-show-search-panel', this.gotoTop);
  },
  methods: {
    showError() {
      this.$bvModal.show('errorAlertModal');
    },
    hideError() {
      this.$bvModal.hide('errorAlertModal');
      this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', '');
    },
    onScroll(event) {
      this.isScroll = Number(event.target.scrollTop) > 150;
    },
    gotoTop() {
      $('.st-content').animate({ scrollTop: 0 }, 1000);
    },
  },
};
</script>

<style>
  @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css');
  @import url('https://longbill.github.io/jquery-date-range-picker/dist/daterangepicker.min.css');
  @import url('https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css');
  @import '/assets/css/sabre-style.css';
</style>
