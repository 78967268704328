<template>
  <div>
    <header-area />

    <section class="flightbooking_area">

      <mobile-sticky-header v-if="device==='mobile'"/>

      <booking-flight-area />

      <mobile-sticky-footer v-if="device==='mobile'"/>

    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'booking-flight-payment-content',
  components: {
    HeaderArea: () => import('@/sabre/common/atom/header'),
    MobileStickyHeader: () => import('./mobile/mobileStickyHeader'),
    MobileStickyFooter: () => import('./mobile/mobileStickyFooter'),
    BookingFlightArea: () => import('./bookingFlightArea'),
  },
  metaInfo() {
    const { meta } = this.$route;
    this.$store.commit('SET_FO_BOOKING_STATE', meta.tag);
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      countries: 'GET_SABRE_COUNTRIES',
      airports: 'GET_SABRE_AIRPORTS',
      flight: 'GET_SABRE_ORDERED_FLIGHT',
      condition: 'GET_SABRE_ORDERED_CONDITION',
    }),
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'sabre-flight-booking');
    this.$store.dispatch('FETCH_SABRE_ORDERED_INFORMATION');
    this.$store.commit('INITIALIZE_SELECTED_SEAT_DATA');
  },
  beforeMount() {
    if (!this.airlineCompanies) this.$store.dispatch('FETCH_SABRE_AIRLINE_COMPANIES');
    if (!this.countries) this.$store.dispatch('FETCH_SABRE_COUNTRIES');
    if (!this.airports) this.$store.dispatch('FETCH_SABRE_AIRPORTS');
  },
};
</script>
