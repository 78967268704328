<template>
  <div>
    <section class="texthead">
      <div class="container px-5">
          <h1>הדר</h1>
      </div>
    </section>

    <search-panel v-if="device==='desktop'"/>
    <search-mobile-panel v-if="device==='mobile'"/>

    <search-result-area />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    SearchPanel: () => import('@/sabre/searchPanel/desktop/searchDesktopPanel'),
    SearchMobilePanel: () => import('@/sabre/searchPanel/mobile/searchMobilePanel'),
    SearchResultArea: () => import('./sabreFoSearchResultArea'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      countries: 'GET_SABRE_COUNTRIES',
      airports: 'GET_SABRE_AIRPORTS',
    }),
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'sabre-flight-search-result');
  },
  beforeMount() {
    // this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId: 'Flight_Only' });

    this.$store.commit('SET_SABRE_CHANGING_FLIGHT_ITEM', null);
    this.$store.dispatch('SABRE_REMOVE_ALL_STORED_DATA');

    if (!this.airlineCompanies) this.$store.dispatch('FETCH_SABRE_AIRLINE_COMPANIES');
    if (!this.countries) this.$store.dispatch('FETCH_SABRE_COUNTRIES');
    if (!this.airports) this.$store.dispatch('FETCH_SABRE_AIRPORTS');
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
};
</script>
