<template>
  <div>
    <section class="texthead">
      <div class="container px-5">
          <h1>הדר</h1>
      </div>
    </section>

    <change-flight-panel-desktop v-if="device==='desktop'"/>
    <change-flight-panel-mobile v-if="device==='mobile'"/>

    <search-result-area />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    SearchResultArea: () => import('./sabreFoSearchResultArea'),
    ChangeFlightPanelDesktop: () => import('./changeReturnFlight/changeFlightPanelDesktop'),
    ChangeFlightPanelMobile: () => import('./changeReturnFlight/changeFlightPanelMobile'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      countries: 'GET_SABRE_COUNTRIES',
      airports: 'GET_SABRE_AIRPORTS',
    }),
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'sabre-change-flight');

    if (!this.airlineCompanies) this.$store.dispatch('FETCH_SABRE_AIRLINE_COMPANIES');
    if (!this.countries) this.$store.dispatch('FETCH_SABRE_COUNTRIES');
    if (!this.airports) this.$store.dispatch('FETCH_SABRE_AIRPORTS');
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
};
</script>
